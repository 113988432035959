<script lang="ts">
  import Button from '$lib/components/button/button.svelte';
  import Testimonial from '$lib/components/testimonial/testimonial.svelte';
  import Text from '$lib/components/text/text.svelte';
  import type { PromoBlock } from '$lib/contentful/models/promo-block';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import Carousel from '../carousel/carousel.svelte';

  export let content: PromoBlock | undefined;
</script>

{#if content}
  {@const {
    title,
    description,
    eyebrow,
    testimonials,
    callsToAction,
    entityId,
    background,
    backgroundImage,
    bordered,
    slim,
    layout,
  } = content}
  <Grid
    {background}
    {bordered}
    {backgroundImage}
    as="div"
    layout="columns"
    class="no-padding gap-y-12 {slim
      ? 'max-md:py-8 md:py-20'
      : 'max-md:py-24 md:py-48 xl:py-80'}"
  >
    <Box
      as="div"
      class="flex flex-col flex-wrap justify-center {layout === 'rows'
        ? 'items-center text-center gap-8'
        : 'items-start gap-4'}"
      span={layout === 'columns' ? 4 : 10}
    >
      {#if eyebrow}
        <Text
          as="p"
          variant="eyebrow-16"
          {entityId}
          fieldId="eyebrow"
          {background}
        >
          {eyebrow}
        </Text>
      {/if}
      {#if title}
        <Text
          {entityId}
          fieldId="title"
          {background}
          variant={slim ? 'display-52' : 'display-128'}
        >
          {title}
        </Text>
      {/if}
      {#if description}
        <Text {background} {entityId} fieldId="description" variant="body-24">
          {description}
        </Text>
      {/if}
      {#if callsToAction.length}
        <div
          class="flex max-xl:flex-col xl:flex-row items-center justify-center gap-4 mt-4"
        >
          {#each callsToAction as { text, entityId, ...rest } (entityId)}
            <Button {...rest}>{text}</Button>
          {/each}
        </div>
      {/if}
    </Box>
    {#if testimonials}
      <Box
        as="div"
        offset={layout === 'columns' ? 8 : 3}
        span={layout === 'columns' ? 5 : 10}
      >
        <Carousel groupSize={2} items={testimonials} let:items>
          <div
            class="w-full gap-4 max-xl:flex max-xl:flex-col xl:flex-row max-xl:items-center xl:grid {layout ===
            'columns'
              ? 'xl:grid-cols-1'
              : 'xl:grid-cols-2'}"
          >
            {#each items as testimonial}
              <Testimonial content={testimonial} />
            {/each}
          </div>
        </Carousel>
      </Box>
    {/if}
  </Grid>
{/if}

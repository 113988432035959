<script lang="ts">
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import Button from '$lib/components/button/button.svelte';
  import Text from '$lib/components/text/text.svelte';
  import InstallationInstructions, {
    type Commands,
  } from '../installation-instructions/installation-instructions.svelte';
  import type { BrowserBlock } from '$lib/contentful/models/browser-block';
  import RichText from '../rich-text/rich-text.svelte';
  import BrowserContent from './browser-content.svelte';

  export let content: BrowserBlock;
  $: ({ eyebrow, heading, body, callsToAction, image, entityId } = content);

  const commands: Commands = {
    mac: 'brew install temporal && temporal server start-dev',
    windows: 'curl -sSf https://temporal.download/cli.sh | sh',
    linux: 'curl -sSf https://temporal.download/cli.sh | sh',
  };
</script>

<Grid as="div" layout="columns" class="overflow-hidden">
  <Box as="div" class="flex max-lg:flex-col lg:flex-row lg:pt-64 gap-16">
    <div class="content">
      {#if eyebrow}
        <Text as="p" class="text-mint" variant="eyebrow-16">
          <slot name="eyebrow">{eyebrow}</slot>
        </Text>
      {/if}
      <Text as="h3" variant="heading-48" class="text-off-white">
        <slot name="heading">{heading}</slot>
      </Text>
      <RichText theme="dark" fieldId="body" {entityId} text={body} />
      {#each callsToAction as { text, ...rest }}
        <Button {...rest}>{text}</Button>
      {/each}
      <InstallationInstructions {commands}>
        <p class="description">
          More ways to
          <a
            class="pulsar-link"
            href="https://docs.temporal.io"
            target="_blank"
            rel="noreferrer">set up your local environment</a
          >.
        </p>
      </InstallationInstructions>
    </div>
    <BrowserContent {image}>
      <img
        class="lg:absolute lg:-translate-x-1/3 lg:-translate-y-2/3 lg:w-3/4 h-full max-lg:w-1/2 max-lg:-translate-y-1/3 max-lg:translate-x-1/2"
        src="/images/code-direction.svg"
        alt="graphic"
      />
    </BrowserContent>
  </Box>
</Grid>

<style lang="postcss">
  .content {
    @apply flex flex-col max-lg:w-full lg:w-1/2 gap-6 max-sm:px-5;
  }
</style>
